<template>
  <div id="app" :class="[classBase + 'app', grayBody ? 'gary-body' : '']">
    <router-view />
  </div>
</template>
<script>
// import { mapGetters } from "vuex";FD
// plugins
import eventBus from "@/plugins/eventBus";

export default {
  components: {},
  data() {
    return {
      token: this.$route.query.token || "",
      grayBody: false
    };
  },
  computed: {},
  watch: {
    $route: "routeWatch"
  },
  async created() {
    // 设置网页灰色
    this.routeWatch();
  },
  async beforeMount() {
    // 获取用户信息
    await this.$store.dispatch("getUserInfo");

    // 青少年模式检查
    eventBus.$emit("checkUnderage");

    // 设置全局鼠标样式
    const mouseImg = this.$store.getters.gameInfo.mouse_css || "";
    if (mouseImg) {
      document.body.style.cursor = `url(${mouseImg}), auto`;
    }

    // 注册一个全局点击事件，用于下拉菜单等点空白区域关闭菜单等操作；
    window.addEventListener("click", this.windowClick);
    if (this.device.desktop) {
      document.querySelector("body").style.minWidth = "1400px";
    }
    if (window.isAppPage && window.goPage) {
      this.$router.replace(window.goPage);
    }
  },
  mounted() {},
  beforeDestroy() {
    // 页面注销时销毁事件
    window.removeEventListener("click", this.windowClick, false);
  },
  methods: {
    // 监听全局点击事件
    windowClick(event) {
      eventBus.$emit("windowClick", event);
    },
    // 监听路由
    routeWatch() {
      if (this.$route.meta.grayBody) {
        this.grayBody = true;
      } else {
        this.grayBody = false;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.pc-app {
  max-width: 100%;
  overflow: auto;
}

// 页面置灰
.gary-body {
  -webkit-filter: grayscale(100%); /* webkit */
  -moz-filter: grayscale(100%); /*firefox*/
  -ms-filter: grayscale(100%); /*ie9*/
  -o-filter: grayscale(100%); /*opera*/
  filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
</style>
